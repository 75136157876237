const FEATURE_TIERS = [
  {
    name: "premium",
    value: 100,
  },
  {
    name: "pro",
    value: 200,
  },
  {
    name: "standard",
    value: 300,
  },
  {
    name: "basic",
    value: 400,
  },
  {
    name: "info",
    value: 900,
  },
];

export const determineOrderMethod = (
  pickupEnabled: boolean,
  deliveryEnabled: boolean,
  featureTier?: number,
  tags?: string[],
) => {
  if (pickupEnabled || deliveryEnabled) {
    return "order on leafly";
  }

  const hasDeliveryTag = tags?.length && tags.includes("delivery");
  if (!pickupEnabled && !deliveryEnabled && !hasDeliveryTag) {
    return "online menu";
  }
  if (!pickupEnabled && !deliveryEnabled && hasDeliveryTag) {
    return "third party";
  }

  const tier = Number.isInteger(featureTier)
    ? FEATURE_TIERS.find((tier) => featureTier === tier.value)?.name
    : featureTier;
  if (tier === "info") {
    return "no menu";
  }

  return "";
};
