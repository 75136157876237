import dynamic from "next/dynamic";
import { useInView } from "react-intersection-observer";

import DispensaryCard, {
  DispensaryCardProps,
} from "components/botanic/DispensaryCard/DispensaryCard";

const PickupReadinessLabel = dynamic(
  () => import("components/botanic/DispensaryCard/PickupReadinessLabel"),
);

const LazyLoadPickupReadinessLabel = ({
  dispensaryId,
}: {
  dispensaryId: DispensaryCardProps["dispensaryId"];
}) => {
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <span ref={ref}>
      {inView && <PickupReadinessLabel dispensaryId={dispensaryId} />}
    </span>
  );
};

export const PickupReadinessDispensaryCard: React.FC<DispensaryCardProps> = ({
  dispensaryId,
  onClick,
  ctaOnClick,
  ...otherProps
}) => {
  return (
    <DispensaryCard
      dispensaryId={dispensaryId}
      renderReadinessLabel={() => (
        <LazyLoadPickupReadinessLabel dispensaryId={dispensaryId} />
      )}
      {...otherProps}
      onClick={(e) => {
        onClick && onClick(e);
      }}
      ctaOnClick={(e) => {
        ctaOnClick && ctaOnClick(e);
      }}
    />
  );
};

export default PickupReadinessDispensaryCard;
